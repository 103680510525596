<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                     <li class="active a">职位管理</li>      
                </div>
                 <div  class="item">  
                    <li class="a" ><router-link to="/index7/xiaoxi" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
            <li style="cursor:pointer;" @click="dialogFormVisible = true">代理商<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog  class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3"  placeholder="输入旧密码" autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <p style="font-size: 22px;

font-weight: 600;
color: #000000;
line-height: 30px;margin:20px 40px">角色管理 <span style="font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 20px;">服务于业务发展的各个角色</span></p>

       <div class="change" style="padding:15px 40px;background:#f5f5f5">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        

         

   <el-tab-pane label="代理商·业务经理" name="six">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show22" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商·业务经理</p>
                            <el-button
                        size="mini"
                        
                        @click="dialogFormVisible2 = true">创建账号</el-button>
                                    <el-dialog title="创建代理商·业务经理" :visible.sync="dialogFormVisible2" class="cj">
                                <el-form :model="form2">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form2.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form2.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                       <el-form-item label="身份证号码" :label-width="formLabelWidth">
                    <el-input v-model="form2.id" autocomplete="off"></el-input>
                    </el-form-item>
  <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form2.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
   
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 10PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证正面</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证反面</p>
       
          <img :src="producImg1"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage1" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
                                      <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                                    <el-input v-model="form2.key_2" autocomplete="off"></el-input>
                                    </el-form-item>
                                
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia1" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="jobnumber"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="warning"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit10(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit11(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                       <el-table-column
                    
                    label="删除账号"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc1(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>
                 <el-pagination
      @size-change="handleSizeChange6"
      @current-change="handleCurrentChange6"
      :current-page="pagination7.page_index"
      :page-sizes="pagination7.page_sizes"
      :page-size="pagination7.page_size"
      :layout="pagination7.layout"
      :total='pagination7.total'
      style="margin-top:20px">
    </el-pagination>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商·业务经理 | 业务统计 </p>
                         

                        

                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   

     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
    
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="customersl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordercount" 
      
    >
    
       </el-table-column>
       
  </el-table>
     <el-pagination
      @size-change="handleSizeChange11"
      @current-change="handleCurrentChange11"
      :current-page="pagination22.page_index"
      :page-sizes="pagination22.page_sizes"
      :page-size="pagination22.page_size"
      :layout="pagination22.layout"
      :total='pagination22.total'
      style="margin-top:20px">
    </el-pagination>
                         
           </div>
                      



            </div>
             
          </el-tab-pane>
            
          
            <el-tab-pane label="调律师" name="seven"><div class="content" style="padding:20px 0;background:#f5f5f5">
            
  
    
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">调律师列表</p>
            <el-button
          size="mini"
           
           @click="dialogFormVisible3 = true">创建账号</el-button>

         

                   <el-dialog title="创建调律师" :visible.sync="dialogFormVisible3" class="cj">
                                <el-form :model="form3">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form3.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                     

                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form3.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                       <el-form-item label="身份证号码" :label-width="formLabelWidth">
                    <el-input v-model="form3.id" autocomplete="off"></el-input>
                    </el-form-item>
  <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form3.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
     <div style="display:flex;justify-content: space-between;text-align:center;padding:0 10PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">1寸证件照上传</p>
          
          <img :src="producImg3"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage3" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">证书上传</p>
       
          <img :src="producImg4"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage4" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 10PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证正面</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证反面</p>
       
          <img :src="producImg1"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage1" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
                                      <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                                    <el-input v-model="form3.key_2" autocomplete="off"></el-input>
                                    </el-form-item>
                            
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia2" style="width:100%">提交审核</el-button>
                                </div>
                                </el-dialog>
                                
   </div>
                            <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="jobnumber"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>
                     <el-table-column
                    label="审核状态"
                  
                    >
                     <template slot-scope="scope" >
                    <p > {{scope.row.shtg | shtg1}}</p>
                    </template>
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="warning"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit12(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit13(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                       <el-table-column
                    
                    label="删除账号"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc2(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>
                  <el-pagination
      @size-change="handleSizeChange7"
      @current-change="handleCurrentChange7"
      :current-page="pagination8.page_index"
      :page-sizes="pagination8.page_sizes"
      :page-size="pagination8.page_size"
      :layout="pagination8.layout"
      :total='pagination8.total'
      style="margin-top:20px">
    </el-pagination>
           </div>
 





            </div></el-tab-pane>
            <el-tab-pane label="琴行" name="eight"><div class="content" style="padding:20px 0;background:#f5f5f5">
 


               <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show33" :class="{'actives':key1==1}">业务统计</p></div>   

                  <div class="content" v-show="key1==1" style="padding:0;background:#f5f5f5">
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">列表</p>
     
                                
   </div>
   <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

          

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input4">
                </el-input>
            </div>

            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck7">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer; background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck8">查询</p>
            </div>
        </div>
                  <el-table
      :border="true"
    :data="tbl"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
    >
 
 <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>
  
     <el-table-column
      label="所属业务经理"
      prop="parentname" 
     >
    
    </el-table-column>
       <el-table-column
      label="会员总数"
      prop="hycount" 
     >
    
    </el-table-column>
       <el-table-column
      label="订单总数"
      prop="ordercount" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>
    



 
  </el-table>
    <el-pagination
      @size-change="handleSizeChange33"
      @current-change="handleCurrentChange33"
      :current-page="pagination44.page_index"
      :page-sizes="pagination44.page_sizes"
      :page-size="pagination44.page_size"
      :layout="pagination44.layout"
      :total='pagination44.total'
      style="margin-top:20px">
    </el-pagination>
           </div>
    </div>

            <div class="content"  v-show="key1==0" style="padding:0;background:#f5f5f5">



                <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">琴行列表</p>
            <el-button
          size="mini"
           
           @click="dialogFormVisible4 = true">创建账号</el-button>


               <el-dialog title="创建琴行" :visible.sync="dialogFormVisible4" class="cj">
                                <el-form :model="form4">
                                      <el-form-item label="琴行名称" :label-width="formLabelWidth">
                                    <el-input v-model="form4.key_3" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form4.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form4.phone" autocomplete="off"></el-input>
                                    </el-form-item>
  <el-form-item label="选择业务经理" :label-width="formLabelWidth">
                                   <el-select v-model="value" placeholder="请选择">
    <el-option
      v-for="item in restaurants"
      :key="item.value"
      :label="item.name"
      :value="item.jobnumber">
    </el-option>
  </el-select>
  </el-form-item>
                                       <el-form-item label="地址" :label-width="formLabelWidth">
                                        <el-input v-model="form4.key_4" autocomplete="off"></el-input>
                                        </el-form-item>
  <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form4.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
   
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 10PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">营业执照上传</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
      

                      
   </div>
                                      <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                                    <el-input v-model="form4.key_2" autocomplete="off"></el-input>
                                    </el-form-item>
                                
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia4" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>

            
   </div>

 <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

          

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input5">
                </el-input>
            </div>

            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck5">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer; background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck6">查询</p>
            </div>
        </div>

                  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
      
    <el-table-column
      label="琴行名称"
      prop="key_3" 
     >

      
    
    </el-table-column>
       <el-table-column
      label="所属业务经理"
      prop="parent" 
     >

      
    
    </el-table-column>
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="key_2"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>
      <el-table-column
        prop="createtime" 
      label="最近登录时间" 
           :show-overflow-tooltip="true" 
      >
    </el-table-column>
      <el-table-column     
      label="用户状态" 
           :show-overflow-tooltip="true" 
     >
      <template slot-scope="scope" >
       <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
      </template>
    </el-table-column>


    

   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
        <el-button
          size="mini"
            type="warning"
            v-if='scope.row.isactive==0'
           @click="handleEdit14(scope.$index, scope.row)">禁用账号</el-button>
             <el-button
                 v-if='scope.row.isactive!==0'
          size="mini"
            type='info'
           @click="handleEdit15(scope.$index, scope.row)">恢复账号</el-button>
        
     </template>
    </el-table-column>

        <el-table-column
                    
                    label="删除账号"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc3(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
  </el-table>
    <el-pagination
      @size-change="handleSizeChange8"
      @current-change="handleCurrentChange8"
      :current-page="pagination9.page_index"
      :page-sizes="pagination9.page_sizes"
      :page-size="pagination9.page_size"
      :layout="pagination9.layout"
      :total='pagination9.total'
      style="margin-top:20px">
    </el-pagination>
           </div>
            </div>
           
                      



            </div></el-tab-pane>
        </el-tabs>
    
       </div> 

            
  </div>
</template>

<script>
import pas from '@/components/password.vue'
import * as qiniu from "qiniu-js";
export default {
        components:{
           pas 
        },
    filters: {
        shtg1(value){
              if(value==0){
                    return '未审核'
                    
                }else if(value==1){
                    return '已审核'
                }else{
                      return '审核不通过'
                }
           
        },
  capitalize: function (value) {
  if(value==0){
      return '正常'
    
  }else {
      return '禁用'
  }
  }
},
 data(){
        return{
            input4:'',
            input5:'',
               pagination22:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
              pagination44:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination7:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
              pagination8:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
               pagination9:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            value:'',
               restaurants: [],
            dialogFormVisible4:false,
             producImg: '',
            producImg3: '',
              producImg4: '',
               producImg1: '',
          imageUrl: "",

			EncodedEntryURI: "",
            jobnumber:'',
               props: { multiple: true },
            key1:0,
              tabPosition: '列表',
             form1: {
          name: '',
          phone:'',
          region: '',
       
        },
               form2: {
          name: '',
          phone:'',
          region: '',
          key_2:'',
          id:'',
          worktime:'',
     
       
        },
               form4: {
          name: '',
          phone:'',
          region: '',
          key_2:'',
            key_3:'',
              key_4:'',
          id:'',
          worktime:'',
        
       
        },
         form3: {
            name: '',
          phone:'',
          region: '',
          key_2:'',
          id:'',
          worktime:'',
            number:''
        },
dialogFormVisible3:false,
options:[],
            password:'',
            isactive:0,
            arr:'',
           dialogFormVisible2:false,
            dialogFormVisible1:false,
            dialogFormVisible33:false,
            dialogFormVisible11:false,
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          date3:[],
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
        activeName: 'six',
        tableData: [],
        tbl:[],
        tb1:[],
        data1:[],
        data2:[],
    
        password2:false,
        password3:'',
        cover:''
        }
        },
       methods:{
           ck5(){
  this.$axios.get("/s/backend/api/user_list?", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
     
       phone:'',
       roleid :8 , jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.pagination9.total =res.data.count
        })
        .catch(function(){
           
        })
           },
           ck6(){
    this.$axios.get("/s/backend/api/user_list?", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
     
       phone:this.input5,
       roleid :8 , jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.pagination9.total =res.data.count
        })
        .catch(function(){
           
        })

           },

            ck7(){},
             ck8(){
                      this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') ,   phone:this.input4}
     
        })

     .then(res=>{
    
          this.tbl = res.data.data
     
 
        })
        .catch(function(){
           
        })
             },
      sc1(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
               if(res.data.code== -1){
         this.$message({
            type: 'error',
            message: res.data.msg
          });
               }else{
  this.$message({
            type: 'success',
            message: '删除成功!'
          });
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :7,
                             'jobnumber_1':localStorage.getItem('jobnumber') 
                        }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                
                            })
                            .catch(function(){
                            
                            })
                         
               }
              
                          
                                
                        })
        .catch(function(){
           
        })  
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
            sc2(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                              if(res.data.code== -1){
 this.$message({
            type: 'error',
            message: res.data.msg
          });
               }else{
                    this.$message({
            type: 'success',
            message: '删除成功!'
          });
   this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :6 ,
                        'jobnumber_1':localStorage.getItem('jobnumber') }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                
                            })
                            .catch(function(){
                            
                            })
                         
                          
               }
                 
                                
                        })
        .catch(function(){
           
        })  
        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },

             sc3(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                              if(res.data.code== -1){
 this.$message({
            type: 'error',
            message: res.data.msg
          });
               }else{
                    this.$message({
            type: 'success',
            message: '删除成功!'
          });
   this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :8,
                        'jobnumber_1':localStorage.getItem('jobnumber') }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                
                            })
                            .catch(function(){
                            
                            })
                         
                          
               }
                 
                                
                        })
        .catch(function(){
           
        })  
        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
               handleSizeChange6(val) {
this.pagination7.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination7.page_index,
       limit :val,
       roleid :7,
                     'jobnumber_1':localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
    this.tableData=res.data.data

    
   
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange6(val) {
       this.pagination7.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination7.page_size,
       roleid :7,
                     'jobnumber_1':localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
    this.tableData=res.data.data
    
      
        })
        .catch(function(){
           
        })
      },



  handleSizeChange11(val) {
this.pagination22.page_size=val

      this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
          page :this.pagination22.page_index,
       limit :val, }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
          this.pagination22.total = res.data.count[0].count
 
        })
        .catch(function(){
           
        })


         
                 
      },
      handleCurrentChange11(val) {
       this.pagination22.page_index=val
     



  this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
          page :val,
       limit :this.pagination22.page_size, }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
          this.pagination22.total = res.data.count[0].count
 
        })
        .catch(function(){
           
        })

      
      },


      
  handleSizeChange33(val) {
this.pagination44.page_size=val

      this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
          page :this.pagination44.page_index,
       limit :val, }
        })

     .then(res=>{
    
          this.tbl = res.data.data
     
 
        })
        .catch(function(){
           
        })


         
                 
      },
      handleCurrentChange33(val) {
       this.pagination44.page_index=val
     



  this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
          page :val,
       limit :this.pagination44.page_size, }
        })

     .then(res=>{
    
       
          this.tbl = res.data.data
        })
        .catch(function(){
           
        })

      
      },














    handleSizeChange7(val) {
this.pagination7.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination7.page_index,
       limit :val,
       roleid :6,
                     'jobnumber_1':localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
   this.tableData=res.data.data
     
   
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange7(val) {
       this.pagination8.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination7.page_size,
       roleid :6,
                     'jobnumber_1':localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
    this.restaurants=res.data.data
        this.pagination8.total = res.data.count
      
        })
        .catch(function(){
           
        })
      },

        handleSizeChange8(val) {
this.pagination9.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination9.page_index,
       limit :val,
       roleid :8,
      'jobnumber_1':localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
   this.tableData=res.data.data
      
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange8(val) {
       this.pagination8.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination9.page_size,
       roleid :8,
        'jobnumber_1':localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
      this.tableData=res.data.data
    
      
        })
        .catch(function(){
           
        })
      },
            changeImage(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
              
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg = "http://gqtlgj.cn/" + res.data.key;
                                       
                                    })
                                    .catch(err => {
                                      
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
        changeImage1(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
      
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg1 = "http://gqtlgj.cn/" + res.data.key;
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
                  changeImage3(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
      
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg3 = "http://gqtlgj.cn/" + res.data.key;
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
               changeImage4(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
      
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg4 = "http://gqtlgj.cn/" + res.data.key;
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
           beforeAvatarUpload(file){
             
               this.postData.key = file.name
    
                this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                       
                            this.postData.token = res.data.uptoken
                          localStorage.setItem('tokens',  res.data.uptoken)
                        })
                        .catch(function(){
                        
                        })
                 this.postData.token = localStorage.getItem('tokens')
               

           },
           uploadSuccess(res) {
               console.log(res)
			this.cover = "http://gqtlgj.cn/" + res.key;
            
	
		},
           handleFileUpload(event){
                  event.preventDefault();
                  this.img = this.$refs.file.files[0];

                        this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                      filename :'this.$refs.file.files[0].name'
                 }
                        })

                    .then(res=>{
                  
                            this.postData.token = res.data.uptoken
                      
                        })
                        .catch(function(){
                        
                        })
                },
             tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
           changeProvinceCity(val) { 
               let arr = []
        
                for(var i =0;i<val.length;i++){
             arr = arr.concat(val[i][2])
            
                }
            this.arr =arr
              
},
tianjia(){},
        tianjia1(){
        
       
             if(this.form2.phone!=='' && this.form2.name !=='' && this.form2.worktime!=='' &&this.form2.id!==''&&this.producImg !==''&&this.producImg1!=='' ){
                 console.log()     
                          var d1 =     this.form2.id.substring(5,9)
                                   var d2 =  this.form2.id.substring(10,12)
                                   var d3 = d1+'-'+d2
               this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                      
                             "birthday":d3,
                        "zjzpurl":'',
                        "sex":'',
                        "address":'',
                        "worktime":this.form2.worktime,
                        "arealist": 'string',
                        "id": this.form2.id,
                          "idurl1": this.producImg,
                        "idurl2":this.producImg1,
                        "key_1": "A",
                        "key_2": this.form2.key_2,
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form2.name,
                        "parent": localStorage.getItem('jobnumber'),
                        "password": 123456,
                        "phone": this.form2.phone,
                        "roleid": 7,
                        "yyzcurl": "string",
                        "yyzz": 'string',
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                this.form2.name=''
                                this.form2.phone=''
                                this.form2.key_2=''
                                  this.form2.id=''
                                this.form2.worktime=''
                                this.producImg1=''
                                this.producImg=''
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible2 = false
                            this.arr= ''
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :7 ,
        jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }else{
                                     this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }

                        })
                        
        .catch(function(){
           
        })  
        }else{
             this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
        }
           },
  tianjia4(){
        
       
   
          if(this.form4.phone!==''&&this.form4.name!==''&&this.form4.worktime!==''&&this.form4.key_2!==''&&this.producImg!==''){
                       
               this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                      
                          "birthday": "1992-04",
                        "zjzpurl":"string",
                        "sex": "string",
                       "address": "string",
                        "worktime":this.form4.worktime,
                        "arealist": [],
                        "id": "string",
                          "idurl1":"string",
                        "idurl2":"string",
                        "key_1": "A",
                         "key_2": this.form4.key_2,
                        "key_3": this.form4.key_3,
                        "key_4":this.form4.key_4,
                        "name": this.form4.name,
                        "parent": this.value,
                        "password": 123456,
                        "phone": this.form4.phone,
                        "roleid": 8,
                        "yyzcurl": "string",
                        "yyzz": this.producImg,
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible4 = false
                            this.arr= ''
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :8, jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }else{
                                     this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }

                        })
                        
        .catch(function(){
           
        })  

          }else{
              this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
          }
       
           },


      tianjia2(){
        
       
             if(this.form3.phone!=='' && this.form3.name !=='' && this.form3.worktime!=='' &&this.form3.id!==''&&this.producImg !==''&&this.producImg1!=='' ){
                 console.log()     
                          var d1 =     this.form3.id.substring(5,9)
                                   var d2 =  this.form3.id.substring(10,12)
                                   var d3 = d1+'-'+d2
               this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                          "birthday":d3,
                        "zjzpurl":this.producImg3,
                        "sex":'',
                        "address":'',
                        "worktime":this.form3.worktime,
                        "arealist":'string',
                        "id": this.form3.id,
                          "idurl1": this.producImg,
                        "idurl2":this.producImg1,
                        "key_1": "A",
                          "key_2": this.form3.key_2,
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form3.name,
                        "parent": localStorage.getItem('jobnumber'),
                        "password": 123456,
                        "phone": this.form3.phone,
                        "roleid": 6,
                        "yyzcurl": "string",
                        "yyzz": this.form3.number,
                        "zsurl": this.producImg4
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                             this.form3.worktime=''
                             this.form3.id=''
                             this.form3.name=''
                             this.form3.phone=''
                             this.producImg3=''
                             this.form3.number=''
                             this.producImg1=''
                                this.producImg4=''
                             this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible3 = false
                            this.arr= ''
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6 , jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }else{
                                     this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }

                        })
                        
        .catch(function(){
           
        })  
        }else{
             this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
        }
           },

 tianjia3(){

            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                          "birthday":this.form3.birthday,
                        "zjzpurl":'',
                        "sex":'',
                        "address":'',
                        "worktime":this.form3.worktime,
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "A",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form3.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form3.phone,
                        "roleid": 6,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": this.cover
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                this.dialogFormVisible3 = false;
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1 , jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }

                        })
                        
        .catch(function(){
           
        })  
           },






show2(){this.key1=1

            this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},

show22(){this.key1=1

            this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
          this.pagination22.total = res.data.count[0].count
 
        })
        .catch(function(){
           
        })


},


show33(){
    
    this.key1=1

            this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tbl = res.data.data
        this.pagination44.total =res.data.count
        })
        .catch(function(){
           
        })


},



show44(){this.key1=1

            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},


show55(){this.key1=1

            this.$axios.get("/s​/backend​/​/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},
show1(){this.key1=0},
           handleClick111(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
              }
           },
handleClick(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
          }else if( tab.name == 'second')
          {
             this.tab2() 
          }else if( tab.name == 'third')
          {
             this.tab3() 
          }else if( tab.name == 'fourth')
          {
             this.tab4() 
          }else if( tab.name == 'five')
          {
             this.tab5() 
          }else if( tab.name == 'six')
          {
             this.tab6() 
          }else if( tab.name == 'seven')
          {
             this.tab7() 
          }else if( tab.name == 'eight')
          {
             this.tab8() 
          }
      },
        //
         tab1(){
            this.$axios.get("/s/backend/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2  , jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
        tab2(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :11 , jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

   tab3(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1, jobnumber_1:localStorage.getItem('jobnumber')   }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

 tab4(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5, jobnumber_1:localStorage.getItem('jobnumber')   }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
 tab5(){
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4 , jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
 tab6(){
     this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :7 , jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

         tab7(){
             
            this.$axios.get("/s/backend/api/user_list?temm=" + new Date().getTime(), {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6 ,
       jobnumber_1:localStorage.getItem('jobnumber') 
         }
        })

     .then(res=>{
    
         this.tableData = res.data.data
            this.pagination8.total =res.data.count
        })
        .catch(function(){
           
        })
        },

         tab8(){
            this.key1=0
            this.$axios.get("/s/backend/api/user_list?", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :8 , jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.pagination9.total =res.data.count
        })
        .catch(function(){
           
        })
        },
      //
           tiania(){
              
            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": this.form1.region,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                                 this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                if(res.data.code==0){
                                       this.dialogFormVisible3=false
                                          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  , jobnumber_1:localStorage.getItem('jobnumber') }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :9, jobnumber_1:localStorage.getItem('jobnumber')   }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })
    
                                }
                        })
        .catch(function(){
           
        })  
           },
          handleEdit(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit1(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





            handleEdit2(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11  , jobnumber_1:localStorage.getItem('jobnumber') }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit3(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },








    handleEdit4(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit5(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





    handleEdit6(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5, jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit7(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },


    handleEdit8(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit9(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit10(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :7 , jobnumber_1:localStorage.getItem('jobnumber') }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit11(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :7 , jobnumber_1:localStorage.getItem('jobnumber') }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },


            handleEdit12(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :6 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit13(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :6 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit14(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit15(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8 , jobnumber_1:localStorage.getItem('jobnumber')  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
       },
        mounted(){

        
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })   
           
this.$axios.get("/s/backend/api/user_list", {
                        params: { 'accessToken':localStorage.getItem('token'),
                    'jobnumber_1':localStorage.getItem('jobnumber'),
                        'limit':20,
                        'page':1,
                        'roleid':7,
                    
                     }
                            })

     .then(res=>{
    
 
      this.restaurants=res.data.data
 
        })
        .catch(function(){
           
        })
         


this.$axios.get("/s/backend/arealist", {
                        params: { 'jobnumber':localStorage.getItem('jobnumber'),
                     }
                            })

     .then(res=>{
    
 
        this.options = res.data.data
 
        })
        .catch(function(){
           
        })
            //选择职位
this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :7,
        jobnumber_1:localStorage.getItem('jobnumber')  }
        })

     .then(res=>{
         this.pagination7.total =res.data.count
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

  

      
      
      this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           this.jobnumber = res.data.data.jobnumber
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>
.red{
    color: red;
}
/deep/.cj .el-dialog{
        width: 35% !important;

  height: 80%;
  overflow-y: scroll;
  
}
/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}
/deep/ .el-dialog{
        width: 30%;

  
  
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }



.actives{
    background: #0034FF  !important;
    color: #fff  !important ;
}

.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
            border-bottom: 1px solid #f5f5f5;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-130%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
            /deep/.el-radio-button__inner{
                padding:8px 15px;
                border-radius: 0;
            }
            /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{


                background: #0034FF;
            }

            .list{
                display: flex;
            }
            .list p {
                padding:8px 15px;
               background: #FFFFFF;font-size: 14px;
border-radius: 4px 0px 0px 0px; cursor: pointer;
            }
</style>